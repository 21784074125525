<template>
    <div class="statistics-data">
        <div class="statistics-item">
            <span class="title">总计</span>
        </div>
        <div class="statistics-item">
            <img
                :src="leaveNumUrl"
                alt=""
            />
            <span class="title">离校人数</span>
            <span class="num">{{ statisticsData.outPersonCount }}</span>
        </div>
        <div class="statistics-item">
            <img
                :src="personTimeUrl"
                alt=""
            />
            <span class="title">离校人次</span>
            <span class="num">{{ statisticsData.outCount }}</span>
        </div>
        <div class="statistics-item">
            <img
                :src="InNumUrl"
                alt=""
            />
            <span class="title">入校人数</span>
            <span class="num">{{ statisticsData.inPersonCount }}</span>
        </div>
        <div class="statistics-item">
            <img
                :src="personTimeUrl"
                alt=""
            />
            <span class="title">入校人次</span>
            <span class="num">{{ statisticsData.inCount }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "statisticsData",
    props: {
        statisticsData: {
            type: Object,
        },
    },
    computed: {
        laveBenNumUrl() {
            return require("@/assets/images/lave-bed-num.png");
        },
        leaveNumUrl(){
            return require("@/assets/images/leaveSchool.png");
        },
        InNumUrl(){
            return require("@/assets/images/inSchool.png");
        },
        personTimeUrl(){
            return require("@/assets/images/person-time.png");
        },
    },
};
</script>

<style scoped lang="scss">
.statistics-data {
    margin: 10px 0px;
    display: flex;
    background-color: #fff;
    padding: 10px 13px;

    .statistics-item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;
        margin-right: 50px;
        padding-right: 50px;
        white-space: normal;

        &:first-child {
            color: #737373;
            font-weight: bold;
            border-right: 1px solid #d7d9db;
        }

        .title {
            white-space: nowrap;
        }

        img {
            margin-right: 8px;
        }

        .num {
            margin-left: 10px;
            font-weight: bold;
            color: #7a8a99;
        }
    }
}
</style>
