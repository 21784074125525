<!-- 校园安全-出入统计 -->
<template>
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-wrapper"
            >
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="1190"
                        :formData="formData"
                        marginBottom="0px"
                        label-width="90px"
                        @clickBtn="clickBtn"
                        @treeClick="filterTreeClick"
                        @treeClear="treeClear"
                    ></expand-filter>
                    <el-button
                        v-hasPermi="['accessStatistics:export']"
                        @click="deriveBtn"
                        :loading="exportLoading"
                        >导出</el-button
                    >
                </div>

                <StatisticsData :statisticsData="countData" />

                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                >
                </table-data>
            </div>

            <DialogWrapper
                :dialogObj="dialogObj"
                @handleClose="handleClose"
            >
                <div class="dialog_main">
                    <div class="dialog_text">请选择要导出的数据范围</div>
                    <div>
                        <el-cascader
                            v-model="exportDataClassIds"
                            style="width: 100%"
                            :options="options"
                            :props="props"
                            collapse-tags
                            clearable
                            placeholder="请选择班级"
                        ></el-cascader>
                    </div>
                    <div class="dialog_text2">未选择默认导出全部数据</div>
                    <div class="dialog_footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button
                            @click="emportBtn"
                            type="primary"
                            >确认</el-button
                        >
                    </div>
                </div>
            </DialogWrapper>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>
  
  <script>
import {
    // 业务组件
    DialogWrapper,
    // 功能组件
    Error,
    Loading,
    debounce,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {CampusSafetyModel} from "@/models/CampusSafety";
import {mapState} from "vuex";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import {downloadFile, formatTreeData} from "@/libs/utils";
import {TemperatureMonitoringModel} from "@/models/TemperatureMonitoring";
import onResize from "@/mixins/onResize";

import StatisticsData from "@/components/scrollWrapper/SSafeInoutStatistics/statisticsData.vue";

export default {
    name: "SSafeInoutStatistics",
    mixins: [onResize],
    components: {
        TableData,
        DialogWrapper,
        Error,
        Loading,
        ExpandFilter,
        StatisticsData,
    },
    data() {
        return {
            // 头部筛选
            startDate: "",
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "datePick",
                        dateType: "datetimerange",
                        valueFormat: "yyyy-MM-dd HH:mm",
                        format: "yyyy-MM-dd HH:mm",
                        label: "",
                        value: "",
                        customClassName: 'no-clear',
                        key: "time",
                        width: "340px",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                        pickerOptions:{
                            onPick: ({ maxDate, minDate }) => {
                                this.startDate = minDate.getTime();
                                if(maxDate){
                                    this.startDate =""
                                }
                            },
                            disabledDate: (time) => {
                                if(this.startDate){
                                    const one = 1000* 3600 * 24 * 30;
                                    const minTime = this.startDate - one;
                                    const maxTime = this.startDate + one;
                                    return (time.getTime() < minTime || time.getTime() > maxTime);
                                }else{
                                    return false
                                }
                            }
                        },
                        clearable:false,
                    },
                    {
                        width: "200px",
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "classIds",
                        list: [],
                        cascaderProps: {
                            multiple: true,
                            value: "id",
                            label: "name",
                            emitPath: false,
                        },
                        expandAll: false,
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "submit",
                        auth: ["accessStatistics:list"],
                    },
                ],
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "",
                        prop: "name",
                        type: "popoverText",
                        align: "left",
                        labelWidth: "200px",
                        fixed: false,
                    },
                    {
                        label: "班主任",
                        prop: "teacherName",
                        type: "popoverText",
                        align: "center",
                    },
                    {
                        label: "离校人数",
                        prop: "outPerson",
                        type: "text",
                        align: "center",
                    },
                    {
                        label: "离校人次",
                        prop: "outNumber",
                        type: "text",
                        align: "center",
                    },
                    {
                        label: "入校人数",
                        prop: "inPerson",
                        type: "text",
                        align: "center",
                    },
                    {
                        label: "入校人次",
                        prop: "inNumber",
                        type: "text",
                        align: "center",
                    },
                ],
                check: false,
                rowkey: "id",
                isExpandAll: false,
                indent: 27,
                height: "",
            },
            // 表格数据
            table_data: [],
            listQuery: {
                time: [],
                schoolId: "",
                startTime: "",
                endTime: "",
                classIds: [],
            },
            countData: {
                outPersonCount: 0, //离校人数
                outCount: 0, //离校人次
                inPersonCount: 0, //入校人数
                inCount: 0, //入校人次
            }, //统计信息
            // 弹出框配置
            dialogObj: {
                title: "导出",
                dialogVisible: false,
                width: "400px",
            },
            // 导出
            exportDataClassIds: [],
            props: {
                multiple: true,
                emitPath: false,
                value: "id",
                label: "name",
            },
            options: [],

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,

            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "",
            ruleForm: {
                personName: "",
                personIdentity: "",
                organ: "",
                accessType: "",
                accessModeName: "",
                pickName: "",
                pickIdCard: "",
                signTime: "",
                signTime: "",
                equipmentAddress: "",
            },
            detailState: false,
            exportLoading: false,
        };
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            templateInfo: (state) => state.templateInfo,
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        // 导出按钮
        deriveBtn() {
            this.dialogObj.dialogVisible = true;
        },
        handleClose() {
            this.exportDataClassIds = [];
            this.dialogObj.dialogVisible = false;
        },
        init() {
            if (this.permissions.indexOf("accessRecord:info") !== -1) {
                this.detailState = true;
            }
            this.listQuery.schoolId = this.schoolId;
            this.getNowTime();
            this.getList();
            this.getTreeOrganData();
            this.getAccessMode();
        },
        // 初始时间
        getNowTime() {
            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // 月份从0开始，所以需要加1
            const currentDate = now.getDate();
            let nyr = `${currentYear}-${
                currentMonth.toString().length == 1
                    ? "0" + currentMonth
                    : currentMonth
            }-${
                currentDate.toString().length == 1
                    ? "0" + currentDate
                    : currentDate
            }`;
            let start = `${nyr} 00:00:00`;
            let end = `${nyr} 23:59:59`;
            this.listQuery.startTime = start;
            this.listQuery.endTime = end;

            this.formData.data.forEach((item) => {
                if (item.key === "time") {
                    item.value = [start, end];
                }
            });
        },
        getList(t) {
            this.loadingTable = true;
            let campusSafety = new CampusSafetyModel();
            campusSafety.getOutInListData(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    let list = [];

                    // 表格数据
                    let newData = formatTreeData(list, "id", "parentOrg");
                    this.table_data = res.data.data;

                    this.getCountData(this.listQuery);
                    this.loadingTable = false;
                });
            });
        },
        getCountData(data) {
            let campusSafety = new CampusSafetyModel();
            campusSafety.getCountData(data).then((res) => {
                this.handleRes(res, () => {
                    this.countData = res.data.data;
                });
            });
        },
        formatRouterTree(data, key, parentKey) {
            let parents = [];
            let children = [];
            data.forEach((item, index) => {
                let find = data.find((i) => item[parentKey] === i[key]);
                if (find && item[parentKey] !== undefined) {
                    children.push(item);
                } else {
                    parents.push(item);
                }
            });
            dataToTree(parents, children, key, parentKey);

            function dataToTree(parents, children, key, parentKey) {
                parents.map((p) => {
                    children.map((c, i) => {
                        if (c[parentKey] === p[key]) {
                            let _c = JSON.parse(JSON.stringify(children));
                            _c.splice(i, 1);
                            dataToTree([c], _c, key, parentKey);
                            if (p.children) {
                                p.children.push(c);
                            } else {
                                p.children = [c];
                            }
                        }
                    });
                });
            }

            return parents;
        },
        getTreeOrganData() {
            this._fet("/school/schoolOrgan/listAuthByCondition", {
                schoolId: this.schoolId,
                jobScope: [3],
            }).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data.forEach((item) => {
                        if (item.key === "classIds") {
                            item.list = formatTreeData(
                                res.data.data.list,
                                "id",
                                "parentOrg",
                            );
                            this.options = item.list;
                        }
                    });
                });
            });
        },

        getAccessMode() {
            this._fet("/accessSchool/accessRecord/getAccessModeList", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data.forEach((item) => {
                        if (item.key === "accessModeName") {
                            item.list = res.data.data.map((i) => ({
                                label: i,
                                value: i,
                            }));
                        }
                    });
                });
            });
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "submit": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    if (this.listQuery.time && this.listQuery.time.length > 0) {
                        this.listQuery.startTime = this.listQuery.time[0] + `:00`;
                        this.listQuery.endTime = this.listQuery.time[1] + `:59`;
                    } else {
                        this.listQuery.endTime = "";
                        this.listQuery.startTime = "";
                    }
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.listQuery.organId = "";
                    this.listQuery.endTime = "";
                    this.listQuery.startTime = "";
                    this.getList(1);
                    break;
                case "export":
                    this.handleExport();
                    break;
            }
        },
        emportBtn() {
            this.handleExport();
            this.handleClose();
        },
        handleExport() {
            this.formData.data.forEach((item) => {
                this.listQuery[item.key] = item.value;
            });
            if (this.listQuery.time && this.listQuery.time.length > 0) {
                this.listQuery.startTime = this.listQuery.time[0] + `:00`;
                this.listQuery.endTime = this.listQuery.time[1] + `:59`;
            } else {
                this.listQuery.endTime = "";
                this.listQuery.startTime = "";
            }
            let obj = {
                schoolId: this.schoolId,
                classIds: this.exportDataClassIds,
                startTime: this.listQuery.startTime,
                endTime: this.listQuery.endTime,
            };
            this.exportLoading = true;
            downloadFile(
                {
                    url: "/accessSchool/accessRecord/exportSchoolAccessStatistics",
                    form: obj,
                },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {},
            );
        },

        treeClear() {
            this.listQuery.organId = "";
        },
        filterTreeClick(data) {
            this.listQuery.organId = data.data.id;
            data.dt.value = data.data.name;
            data.selRef.blur();
        },
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            });
        },
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
  
<style lang="scss" scoped>
.center-main {
    padding: 0;
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    justify-content: space-between;

    .expand-filter {
        padding: 0;
    }
}
.content {
    border-bottom: 1px solid #f2f6fe;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
::v-deep .el-range-editor.el-input__inner {
    padding: 3px;
}
.table-data {
    border-radius: 4px;
    overflow: hidden;
}
::v-deep .el-table {
    .text-ellipsis {
        display: inline;
    }
    .el-table__row .el-table__cell:first-child .cell {
        padding-left: 62px;
    }

    [class*="el-table__row--level"] {
        .el-table__expand-icon {
            margin-right: 4px;

            &:hover .el-icon-arrow-right {
                background-color: #3c7fff;
            }
        }

        .el-table__expand-icon .el-icon-arrow-right {
            transform: rotate(90deg);
            color: #ffffff;
            font-size: 13px;
            width: 16px;
            height: 16px;
            line-height: 17px;
            text-align: center;
            background-color: #bfbfbf;
            border-radius: 50%;
            transition: rotate 0.2s ease-in;
        }

        .el-table__expand-icon--expanded .el-icon-arrow-right {
            transform: rotate(-180deg) !important;
            background-color: #3c7fff;
        }

        .el-table__placeholder {
            width: 0;
        }
    }
}
.dialog_main {
    .dialog_text {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
    }
    .dialog_text2 {
        text-align: left;
        font-size: 14px;
        padding: 10px 0;
    }
    .dialog_footer {
        text-align: right;
    }
}
</style>
<style>
.no-clear .el-button--text {
    display: none;
}
</style>
